import React from "react";
import styled from "styled-components";

import { Switch, Button } from "@blueprintjs/core";

import EnterpriseController from "js/react/views/Admin/controllers/EnterpriseController";

const Container = styled.div`
    >p {
        font-size: 18px;
        color: #2d2d2d;
        margin-bottom: 5px;
    }
`;

const ControlsContainer = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;

    >.bp4-control {
        margin-bottom: 0;
        margin-right: 20px;
    }

    >.bp4-button {
        width: 200px;
    }
`;

function Filters({ fetching, filters: { showOnlyMyInvites } }) {
    return (<Container>
        <p>Filters</p>
        <ControlsContainer>
            <Switch checked={showOnlyMyInvites} label="Show only my invites" disabled={fetching} onChange={() => EnterpriseController.updateFilters({ showOnlyMyInvites: !showOnlyMyInvites })} />
            <Button rightIcon="refresh" intent="success" text="Refresh" disabled={fetching} loading={fetching} onClick={() => EnterpriseController.fetchInvitesAndPrices()} />
        </ControlsContainer>
    </Container>);
}

export default EnterpriseController.withState(Filters);
