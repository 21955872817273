import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactJson from "react-json-view";

import FetchingClickShield from "js/react/components/FetchingClickShield";

const Container = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vh - 58px);
    overflow-y: auto;
`;

const Error = styled.div`
    color: red;
    font-weight: bold;
    padding: 20px;
`;

export default function Debug() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [json, setJson] = useState(null);

    useEffect(() => {
        const search = new URLSearchParams(window.location.search);
        const url = search.get("url");

        if (!url) {
            setLoading(false);
            setError("No URL provided");
            return;
        }

        fetch(url)
            .then(res => res.json())
            .then(setJson)
            .catch(err => {
                setError(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (<Container>
        <FetchingClickShield visible={loading} />
        {!json && error && <Error>Error: {error}</Error>}
        {json && <ReactJson theme="monokai" displayDataTypes={false} collapsed={true} src={json} />}
    </Container >);
}
