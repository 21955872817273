import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import { Button } from "@blueprintjs/core";

import { presentations as presentationsApi, slides as slidesApi } from "apis/callables";
import Api from "js/core/api";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import { getThemeFromPresentationModel } from "common/themes";
import { SharedTheme } from "js/core/models/sharedTheme";
import { ITheme } from "common/interfaces";

import { SwitchInput, TextInput, NumericInput } from "../components/Inputs";

interface WindowWithRenderer extends Window {
    thumbnailRendererPromise: Promise<void>;
    thumbnailRenderer: any;
}

const Container = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vh - 58px);
    overflow-y: auto;
    background-color: #e6e6e6;
`;

const ControlsContainer = styled.div`
    display: flex;
    flex-flow: row;
    gap: 20px;
    padding: 20px;
    align-items: center;
`;

const RendererIframeContainer = styled.div`
    width: 100%;
    height: calc(100% - 117px);
    border: none;
    position: relative;
`;

const RendererIframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
`;

const ErrorMessage = styled.div`
    color: red;
    font-weight: bold;
    padding: 20px;
`;

export default function Renderer() {
    const [loading, setLoading] = useState(false);
    const [legacy, setLegacy] = useState(false);
    const [slideIndex, setSlideIndex] = useState(1);
    const [presentationId, setPresentationId] = useState("");
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [rendererInitialized, setRendererInitialized] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const iframeRef = useRef<HTMLIFrameElement>(null);
    const shouldRenderOnIframeLoad = useRef(false);

    useEffect(() => {
        const search = new URLSearchParams(window.location.search);

        const legacy = search.get("legacy");
        const presentationId = search.get("presentationId");
        const slideIndex = search.get("slideIndex");

        setLegacy(legacy === "true");
        setPresentationId(presentationId ?? "");
        setSlideIndex(slideIndex && !isNaN(parseInt(slideIndex)) ? parseInt(slideIndex) : 1);

        if (presentationId) {
            shouldRenderOnIframeLoad.current = true;
        }
    }, []);

    useEffect(() => {
        if (error) {
            setError(null);
        }

        const search = new URLSearchParams(window.location.search);
        search.set("presentationId", presentationId);
        search.set("slideIndex", slideIndex.toString());
        search.set("legacy", legacy.toString());
        window.history.replaceState({}, "", `${window.location.pathname}?${search.toString()}`);
    }, [presentationId, slideIndex, legacy]);

    useEffect(() => {
        setIframeLoaded(false);
        setRendererInitialized(false);

        if (presentationId) {
            shouldRenderOnIframeLoad.current = true;
        }
    }, [legacy]);

    const handleIframeLoad = () => {
        setIframeLoaded(true);

        if (shouldRenderOnIframeLoad.current) {
            handleRender();
            shouldRenderOnIframeLoad.current = false;
        }
    };

    const handleRender = async () => {
        if (!presentationId) {
            return;
        }

        setLoading(true);

        try {
            const presentation = await presentationsApi.getPresentation({ id: presentationId });
            const slideId = Object.entries(presentation.slideRefs).find(([, index]) => index === (slideIndex - 1))?.[0];

            if (!slideId) {
                throw new Error("Slide not found");
            }

            const { slide } = await slidesApi.getSlide({ id: slideId, presentationId });

            let theme: ITheme;
            if (presentation.sharedThemeId) {
                const themeModel = new SharedTheme({ id: presentation.sharedThemeId });
                await themeModel.load();

                theme = themeModel.attributes;
            } else {
                theme = getThemeFromPresentationModel(presentation) as unknown as ITheme;
            }

            const rendererPromise = (iframeRef.current?.contentWindow as WindowWithRenderer).thumbnailRendererPromise;
            if (!rendererPromise) {
                throw new Error("Renderer not found");
            }

            await rendererPromise;

            const renderer = (iframeRef.current?.contentWindow as WindowWithRenderer).thumbnailRenderer;
            if (!renderer) {
                throw new Error("Renderer not found");
            }

            if (!rendererInitialized) {
                await renderer.initialize();
                setRendererInitialized(true);
            } else {
                await renderer.completeTask();
            }

            const { token } = await Api.customToken.get();

            await renderer.start({
                slideModel: slide,
                slideIndex: slideIndex - 1,
                theme,
                authToken: token,
                hideBaiBranding: true,
                // Legacy
                hideSmartSlideWatermark: true
            });
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (<Container>
        <ControlsContainer>
            <TextInput label="Presentation ID" value={presentationId} onChange={setPresentationId} disabled={loading} />
            <NumericInput label="Slide Index" value={slideIndex} onChange={setSlideIndex} disabled={loading} minValue={1} />
            <SwitchInput label="Legacy" value={legacy ? "v10" : "v11"} onChange={value => setLegacy(value === "v10")} disabled={loading} values={["v10", "v11"]} />
            <Button onClick={handleRender} disabled={loading || !iframeLoaded || !presentationId}>Render</Button>

            {error && <ErrorMessage>Error: {error}</ErrorMessage>}
        </ControlsContainer>
        <RendererIframeContainer>
            <FetchingClickShield visible={!iframeLoaded} backgroundColor="#4b4e55" />
            <RendererIframe onLoad={handleIframeLoad} src={legacy ? `/renderer?legacy=true` : `/renderer`} ref={iframeRef} />
        </RendererIframeContainer>
    </Container >);
}
