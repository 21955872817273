import React, { Component } from "reactn";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { pageStyles } from "../styles/page_styles";
import TableReport from "../components/TableReport";
import KeyValueReport from "../components/KeyValueReport";

class Reports extends Component {
    render() {
        const { classes, handleShowDialog } = this.props;
        return (
            <div className={classes.page}>
                <h2 className={classes.pageTitle}>Reports Page</h2>
                <div className={classes.blockGrid}>
                    <KeyValueReport
                        handleShowDialog={handleShowDialog}
                        bodyParamName="userId"
                        requestURL="/admin/user-report"
                        columns={[
                            {
                                key: "photoURL",
                                render: (key, value) => (
                                    <img key={key} width={50} src={value} />
                                )
                            },
                            {
                                key: "name",
                                label: "Name"
                            },
                            {
                                key: "email",
                                label: "Email"
                            },
                            {
                                key: "emailVerified",
                                label: "Email Verified"
                            },
                            {
                                key: "userId",
                                label: "User ID"
                            },
                            {
                                key: "creationTime",
                                label: "Account Created"
                            },
                            {
                                key: "lastSignInTime",
                                label: "Last Sign In"
                            },
                            {
                                key: "providers",
                                label: "Providers"
                            },
                            {
                                key: "presentationCount",
                                label: "Presentation Count"
                            },
                            {
                                key: "slideCount",
                                label: "Slide Count"
                            },
                            {
                                key: "currentPlan",
                                label: "Current Plan"
                            },
                            {
                                key: "trialEndsTimestamp",
                                label: "Trial End Date"
                            }
                        ]}
                        description="Get user information."
                        title="User Info"
                        placeholder="User ID or email"
                    />
                    <KeyValueReport
                        handleShowDialog={handleShowDialog}
                        bodyParamName="teamId"
                        requestURL="/admin/team-report"
                        columns={[
                            {
                                key: "name",
                                label: "Name"
                            },
                            {
                                key: "teamId",
                                label: "Team ID"
                            },
                            {
                                key: "numMembers",
                                label: "Number of Team Members"
                            },
                            {
                                key: "numSeats",
                                label: "Number of Seats"
                            },
                            {
                                key: "usedSeats",
                                label: "Seats being used"
                            },
                            {
                                key: "members",
                                label: "Member Info"
                            },
                        ]}
                        description="Get team information."
                        title="Team Info"
                        placeholder="Team ID"
                    />
                    <KeyValueReport
                        handleShowDialog={handleShowDialog}
                        bodyParamName="orgId"
                        requestURL="/admin/org-report"
                        columns={[
                            {
                                key: "domain",
                                label: "Domain"
                            },
                            {
                                key: "createdAt",
                                render: (key, value) => (
                                    <li key={key}>
                                        <b>Created:</b> {new Date(value).toLocaleDateString()}
                                    </li>
                                )
                            },
                            {
                                key: "defaultTeamId",
                                label: "Primary Team Id"
                            },
                            {
                                key: "admins",
                                render: (key, value) => (
                                    <li key={key}>
                                        <b>Admins:</b> {value.map(admin => admin.email).join(", ")}
                                    </li>
                                )
                            },
                            {
                                key: "domainVerificationKey",
                                label: "Domain Verification Key"
                            },
                            {
                                key: "customerId",
                                label: "Stripe Customer Id"
                            },
                            {
                                key: "stripeSubscriptionId",
                                label: "Stripe Subscription Id"
                            },
                            {
                                key: "subscriptionStatus",
                                label: "Subscription Status"
                            },
                        ]}
                        description="Get org information."
                        title="Org Info"
                        placeholder="Org ID"
                    />
                    <KeyValueReport
                        handleShowDialog={handleShowDialog}
                        bodyParamName="presentationId"
                        requestURL="/admin/presentation-report"
                        columns={[
                            {
                                key: "userId",
                                label: "User Id"
                            },
                            {
                                key: "slideCount",
                                label: "Slide Count"
                            }
                        ]}
                        description="Get presentation information."
                        title="Presentation Info"
                        placeholder="Presentation or Link ID"
                    />
                    <TableReport
                        handleShowDialog={handleShowDialog}
                        bodyParamName="userEmailOrUid"
                        requestURL="/admin/deleted-presentation"
                        columns={[
                            {
                                Header: "Presentation Id",
                                accessor: "value"
                            },
                            {
                                Cell: props => (
                                    <span className={"number"}>
                                        {moment(props.value).format("LLL")}
                                    </span>
                                ),
                                Header: "Time",
                                accessor: "time"
                            },
                            {
                                Header: "IP Address",
                                accessor: "ipaddress"
                            }
                        ]}
                        description="Show the presentations deleted by a user."
                        title="Deleted Presentations"
                        placeholder="User ID or email"
                    />
                    <TableReport
                        handleShowDialog={handleShowDialog}
                        bodyParamName="userEmailOrUid"
                        requestURL="/admin/users-presentations"
                        columns={[
                            {
                                Header: "Presentation Id",
                                accessor: "id"
                            }
                        ]}
                        description="Show all presentations for a user."
                        title="All User's Presentations"
                        placeholder="User ID or email"
                    />
                </div>
            </div>
        );
    }
}

Reports.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTheme(withStyles(pageStyles)(Reports));
